<template>
  <div>
    <a-row style="background-color: #fff; padding: 8px 12px; margin-bottom: 8px">
      <a-col :span="8">
        <div style="margin: 8px; font-size: 16px">
          <span>统计日期: {{ statisticalDate }}</span>
        </div>
      </a-col>
      <a-col :span="8">
        <div style="text-align: center; background-color: #fff; font-size: 26px; font-weight: bold">WMS仓库综合看板</div>
      </a-col>
      <a-col :span="8">
        <div style="margin: 8px; font-size: 16px">
          <span style="float: right">当前时间: {{ currentTime }}</span>
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[8, 8]">
      <a-col :span="8">
        <StockInStatsCard />
      </a-col>
      <a-col :span="8">
        <StockOutStatsCard />
      </a-col>
      <a-col :span="8">
        <InventoryWarningCard />
      </a-col>
      <a-col :span="8">
        <StockInTrendCard />
      </a-col>
      <a-col :span="8">
        <StockOutTrendCard />
      </a-col>
      <a-col :span="8">
        <InventoryProportionCard />
      </a-col>
      <a-col :span="16">
        <InventoryTrendCard />
      </a-col>
      <a-col :span="8">
        <StockAgeCard />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {
    StockAgeCard: () => import("./StockAgeCard"),
    InventoryProportionCard: () => import("./InventoryProportionCard"),
    InventoryTrendCard: () => import("./InventoryTrendCard"),
    InventoryWarningCard: () => import("./InventoryWarningCard"),
    StockInTrendCard: () => import("./StockInTrendCard"),
    StockInStatsCard: () => import("./StockInStatsCard"),
    StockOutTrendCard: () => import("./StockOutTrendCard"),
    StockOutStatsCard: () => import("./StockOutStatsCard"),
  },
  data() {
    return {
      stockInImage: require("./StockIn.png"),
      stockOutImage: require("./StockOut.png"),
      materialImage: require("./Material.png"),
      statisticalDate: "",
      currentTime: "",
    };
  },
  methods: {
    initData() {
      this.statisticalDate = moment().format("YYYY-MM-DD");
      setInterval(() => {
        this.currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }, 1000);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
